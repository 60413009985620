import React from 'react'
import { css } from '@emotion/react'
import { Typography, Paper, Tooltip } from '@mui/material'
import { memo } from 'react'
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState'
import { Variant } from '@mui/material/styles/createTypography'
import useMedia from '~/hooks/useMedia'
import { QuoteBoxToolTip } from '~/modules/SDK/socket/QuoteBoxToolTip'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { jc, flex } from '~/modules/AppLayout/FlexGridCss'
import { BLACK, WHITE, globalYellow } from '~/modules/AppLayout/Colors'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useMount, useUnmount } from 'react-use'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { getRelativeStrongorWeak, getPercentagDeltaColor } from '~/modules/AppLayout/colorUtils'
import { useSnapshot } from 'valtio'
import { useMonitorSelectionStore } from '~/modules/monitors/useMonitorSelectionStore'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { useThemeOfParent } from '~/components/theme/useThemeOfParent'

type FontVariant = {
  symbol: Variant
  price: Variant
  contribution: Variant
}

const CONTRIBUTION_COLOR = globalYellow.y500

export const sizes: { small: FontVariant; medium: FontVariant; large: FontVariant } = {
  small: {
    symbol: 'subtitle2',
    price: 'subtitle2',
    contribution: 'subtitle2',
  },
  medium: { symbol: 'h6', price: 'subtitle2', contribution: 'subtitle1' },
  large: {
    symbol: 'h5',
    price: 'subtitle2',
    contribution: 'subtitle1',
  },
}

export const SignalrTwStockQuote = memo<
  ReactProps<{
    isTpex: boolean
    symbol: Signalr.SymbolString
    size?: keyof typeof sizes
  }>
>(function SignalrTwStockQuote(props) {
  const theme = useThemeOfParent()

  const { isPhone, isPc } = useMedia()
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol])
  const snap = useSnapshot(useMonitorSelectionStore)

  const quoteChanges = processQuoteToReadable(
    symbolInfo as Socket.ValueOfOHLC,
    snap.baseRefIsTodayOpen,
  )
  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)
  const size = isPhone ? sizes.small : props.size ? sizes[props.size] : sizes.medium
  const width = !isPc
    ? props.size === 'small'
      ? '18%'
      : props.size === 'medium'
      ? '22%'
      : '31%'
    : '100%'

  const boxRootCss = css`
    ${flex.v.crossCenter};
    ${jc.spaceBetween}
    box-sizing: border-box;
    border-radius: 8px;
    padding: ${isPhone ? 2 : 4}px;
    height: 100%;
    user-select: none;
    min-height: 64px;
    ${isPc || 'margin-right: 8px;'}
    ${isPc || `width: ${width}; margin-bottom: 8px;`}
  `
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc')
  })
  const { acts } = useWeightedStocksPoolState.useContainer()

  /** 瀏覽模式是上櫃，則要拿OTCA */
  const indexValue = useSignalrStoreValueOHLC(
    state => state.value[props.isTpex ? 'OTCA' : 'TSEA'],
  ) ?? {
    close: 0,
    hight: 0,
    low: 0,
  }

  if (!symbolInfo || !indexValue) return null
  const indexChanged = (indexValue.close * acts.getWeight(symbolInfo.symbol)) / symbolInfo.close
  const dataChangePoint =
    symbolInfo.close - (snap.baseContributionPointIsToday ? symbolInfo.open : symbolInfo.prevRef)
  quoteChanges.contributionPoints = parseFloat((indexChanged * dataChangePoint).toFixed(2))

  return (
    <Tooltip
      title={
        <QuoteBoxToolTip
          enableChart={true}
          enableVolume={true}
          enableTradeValue={true}
          data={symbolInfo}
          quoteChanges={quoteChanges}
          intraday={true}
        />
      }
    >
      <Paper
        elevation={8}
        css={css`
          ${boxRootCss}
          text-shadow: ${BLACK} 0.1em 0.1em 0.2em;
          background: ${snap.isRealtimeStatus
            ? getRelativeStrongorWeak(symbolInfo, theme.isDark)
            : getPercentagDeltaColor(quoteChanges.closeChangePercent, theme.isDark)};
          ${theme.isLight ? 'box-shadow: 0 0 0 0;' : null};
          ${quoteAnimation}

          #__next & {
            color: ${WHITE};
          }
        `}
      >
        <Typography variant={size.symbol}>
          <SymbolName symbol={props.symbol as AnyFIXME}></SymbolName>
        </Typography>

        <Typography variant={size.price}>${symbolInfo.close}</Typography>
        <Typography
          css={css`
            color: ${CONTRIBUTION_COLOR};
          `}
          variant={size.contribution}
        >
          {quoteChanges.contributionPoints}
        </Typography>
      </Paper>
    </Tooltip>
  )
})
